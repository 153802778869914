<template>
  <div id='ImageViewer'>
     <!-- Do not use default "grow" transition, as it interferes with canvas scaling -->
    <v-dialog
      :value='active' v-if='active'
      fullscreen
      transition='dialog-bottom-transition'
    >
      <v-container id="container" fill-height fluid >
        <MarkupImage id='MarkupImage'
          :src='src'
          :srcMarkup='srcMarkup'
          :save="onSave"
        />
      </v-container>
      <div style='position:fixed;top:0px;right:0px;width:auto;' class='pa-4'>
        <v-btn fab small text dark class='float-right' @click='close'><v-icon large>mdi-close</v-icon></v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#container{
  background-color: #272727;
}

</style>

<script>
//import { /* mapGetters, */  mapActions } from 'vuex';
import MarkupImage from './MarkupImage'
export default {
  name: 'ImageViewer',
  components: { MarkupImage },
  props: {

  },
  data(){
    return {
      active: false,
      src: null,
      srcMarkup: null,
      onSave: null,
    }
  },
  computed: {},
  methods: {
    open(src, srcMarkup, onSave){
      this.src = src;
      this.onSave = onSave;
      this.srcMarkup = srcMarkup;
      this.active = true;
    },
    close(){
      this.active = false;
    },
    toggleMarkup(){
      this.showMarkup = !this.showMarkup;
    },

  },
  created(){

  },
  mounted(){
  }
}
</script>
