var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "drawing"
    }
  }, [_c('div', {
    style: {
      width: "".concat(_vm.width * _vm.scale, "px"),
      height: "".concat(_vm.height * _vm.scale, "px")
    },
    attrs: {
      "id": "canvasArea"
    }
  }, [_vm.viewMode == 1 ? _c('div', {
    staticClass: "layer white",
    staticStyle: {
      "opacity": "0.8"
    }
  }) : _vm._e(), _c('canvas', {
    ref: "canvas_draw",
    staticClass: "layer",
    style: {
      opacity: _vm.viewMode == 2 ? 0.1 : 0.999
    },
    attrs: {
      "width": _vm.width,
      "height": _vm.height
    }
  }), _c('canvas', {
    ref: "canvas_mouse",
    staticClass: "layer",
    style: {
      display: _vm.edit ? "block" : "none"
    },
    attrs: {
      "width": _vm.width,
      "height": _vm.height
    },
    on: {
      "mousedown": _vm.mouseDown,
      "touchstart": function touchstart($event) {
        $event.preventDefault();
        return _vm.mouseDown.apply(null, arguments);
      },
      "mouseup": _vm.mouseUp,
      "touchend": function touchend($event) {
        $event.preventDefault();
        return _vm.mouseUp.apply(null, arguments);
      },
      "mouseleave": _vm.mouseUp,
      "mousemove": function mousemove(e) {
        return _vm.mouseMoveDx.call(e);
      },
      "touchmove": function touchmove($event) {
        $event.preventDefault();
        return function (e) {
          return _vm.mouseMoveDx.call(e);
        }.apply(null, arguments);
      },
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
      }
    }
  })]), _c('div', {
    attrs: {
      "id": "toolbarArea"
    }
  }, [_c('div', {
    staticClass: "pa-2",
    staticStyle: {
      "max-width": "70vw"
    },
    attrs: {
      "id": "toolbarInner"
    }
  }, [_vm.edit ? [_c('v-speed-dial', {
    staticClass: "mr-4",
    staticStyle: {
      "display": "inline-block"
    },
    attrs: {
      "direction": "bottom"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "fab": "",
            "small": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "large": "",
            "color": _vm.colour
          }
        }, [_vm._v("mdi-circle")])], 1)];
      },
      proxy: true
    }], null, false, 315536959)
  }, _vm._l(_vm.colours, function (c) {
    return _c('v-btn', {
      key: c,
      attrs: {
        "fab": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          _vm.colour = c;
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "large": "",
        "color": c
      }
    }, [_vm._v("mdi-circle")])], 1);
  }), 1), _c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "fab": "",
      "small": ""
    },
    on: {
      "click": _vm.toggleTool
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.tool == "draw" ? "mdi-eraser" : "mdi-pencil"))])], 1), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "fab": "",
      "small": "",
      "color": _vm.size == "s" ? "primary" : "white"
    },
    on: {
      "click": function click($event) {
        _vm.size = "s";
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-circle-medium")])], 1), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "fab": "",
      "small": "",
      "color": _vm.size == "m" ? "primary" : "white"
    },
    on: {
      "click": function click($event) {
        _vm.size = "m";
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-circle-medium")])], 1), _c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "fab": "",
      "small": "",
      "color": _vm.size == "l" ? "primary" : "white"
    },
    on: {
      "click": function click($event) {
        _vm.size = "l";
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-circle")])], 1), _c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "fab": "",
      "small": ""
    },
    on: {
      "click": _vm.toggleView
    }
  }, [_c('v-icon', [_vm._v("mdi-circle-opacity")])], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "loading": _vm.saving,
      "disabled": _vm.saved,
      "color": "primary"
    },
    on: {
      "click": _vm.clickSave
    }
  }, [_c('v-icon', [_vm._v("mdi-content-save")])], 1)] : [_c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "dark": ""
    },
    on: {
      "click": _vm.clickEdit
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "dark": ""
    },
    on: {
      "click": _vm.toggleView
    }
  }, [_c('v-icon', [_vm._v("mdi-circle-opacity")])], 1)]], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }