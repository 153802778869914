var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {}, [_c('v-btn', {
    on: {
      "click": _vm.open
    }
  }, [_vm._v("Markup Test")]), _c('v-btn', {
    on: {
      "click": _vm.open2
    }
  }, [_vm._v("Markup Test")]), _c('ImageViewer', {
    ref: "viewer"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }