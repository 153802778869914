/*

*/

class Debounce{

  constructor(fn){
    this.fn = fn;
    this.timeout = null;
  }

  call(...args){
    if(this.timeout)
      window.cancelAnimationFrame(this.timeout);

    window.requestAnimationFrame(() => {
      this.fn.apply(this, args);
    });
  }

}

export default Debounce;
