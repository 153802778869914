<template>
  <v-container class=''>
    <v-btn @click='open'>Markup Test</v-btn>
    <v-btn @click='open2'>Markup Test</v-btn>
    <ImageViewer
      ref='viewer'
    />
  </v-container>
</template>

<style lang="scss" scoped>
</style>


<script>
//import Vue from 'vue';
import { /* mapGetters, */  mapActions } from 'vuex';
import ImageViewer from '@/components/ImageViewer/Viewer.vue'
export default {
  name: 'MarkupTest',
  components: {
    ImageViewer
  },
  data(){ return {
    drawing: null,
  }},
  watch: {
    $route: { handler(){ this.SET_TITLE('Markup Test'); }, immediate: true },
  },
  methods: {
    ...mapActions("UI", ["SET_TITLE"]),
    ...mapActions("API", ["API"]),
    open(){
      this.$refs.viewer.open(
        "https://external-preview.redd.it/GOkP8onbuyjGmN9Rc8Que5mw21CdSw6OuXpAKUuE6-4.jpg?auto=webp&s=2bc0e522d1f2fa887333286d557466b2be00fa5e",
        this.drawing,
        this.saveDrawing
      );
    },
    open2(){
      this.$refs.viewer.open(
        this.drawing,
      );
    },
    saveDrawing(image){
      this.drawing = image;
      return this.API({ endpoint: 'auth/check' });
    }
  },
}
</script>
