var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "MarkupImage"
    }
  }, [_c('div', {
    staticClass: "white",
    staticStyle: {
      "margin": "auto",
      "position": "relative"
    },
    style: {
      width: "".concat(_vm.inner.width * _vm.inner.scale - 1, "px"),
      height: "".concat(_vm.inner.height * _vm.inner.scale - 1, "px"),
      top: "calc(50% - ".concat((_vm.inner.height * _vm.inner.scale - 1) / 2, "px)")
    }
  }), _c('div', {
    ref: "bg",
    staticStyle: {
      "width": "100%",
      "height": "100%",
      "position": "absolute",
      "top": "0",
      "background-position": "center",
      "background-size": "contain",
      "background-repeat": "no-repeat"
    },
    style: {
      backgroundImage: "url(\"".concat(_vm.src, "\")")
    }
  }), (_vm.srcMarkup || _vm.save) && _vm.ready ? _c('Markup', {
    attrs: {
      "id": "drawing",
      "srcMarkup": _vm.srcMarkup,
      "width": _vm.inner.width,
      "height": _vm.inner.height,
      "scale": _vm.inner.scale,
      "save": _vm.save
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }