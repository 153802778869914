<template>
  <div id="drawing">
    <div id='canvasArea' :style='{ width:`${width*scale}px`, height: `${height*scale}px` }'>
      <div class='layer white' v-if='viewMode==1' style='opacity:0.8;'></div>
      <canvas class='layer' ref='canvas_draw'
        :width='width' :height='height'
        :style='{ opacity: viewMode==2?0.1:0.999 }'
      ></canvas>
      <canvas class='layer' ref='canvas_mouse'
        :width='width' :height='height'
        :style='{ display: edit ? "block" : "none" }'
        @mousedown='mouseDown' @touchstart.prevent='mouseDown'
        @mouseup='mouseUp' @touchend.prevent='mouseUp' @mouseleave='mouseUp'
        @mousemove='e=>mouseMoveDx.call(e)'
        @touchmove.prevent='e=>mouseMoveDx.call(e)'
        @contextmenu.prevent
      ></canvas>
    </div>
    <div id="toolbarArea">
      <div id='toolbarInner' class='pa-2' style='max-width: 70vw;'>
        <template v-if='edit'>
          <v-speed-dial class='mr-4' direction='bottom' style='display:inline-block;'>
            <template v-slot:activator>
              <v-btn fab small><v-icon large :color='colour'>mdi-circle</v-icon></v-btn>
            </template>
            <v-btn v-for='c in colours' v-bind:key='c' fab small @click='colour = c'><v-icon large :color='c'>mdi-circle</v-icon></v-btn>
          </v-speed-dial>
          <v-btn fab small class='mr-4' @click='toggleTool'>
            <v-icon>{{tool=="draw"?"mdi-eraser":"mdi-pencil"}}</v-icon>
          </v-btn>
          <v-btn fab small class='mr-2' @click='size = "s"' :color='size=="s"?"primary":"white"'><v-icon small>mdi-circle-medium</v-icon></v-btn>
          <v-btn fab small class='mr-2' @click='size = "m"' :color='size=="m"?"primary":"white"'><v-icon large>mdi-circle-medium</v-icon></v-btn>
          <v-btn fab small class='mr-4' @click='size = "l"' :color='size=="l"?"primary":"white"'><v-icon>mdi-circle</v-icon></v-btn>
          <v-btn fab small class='mr-4' @click='toggleView'><v-icon>mdi-circle-opacity</v-icon></v-btn>
          <v-btn fab small @click='clickSave' :loading='saving' :disabled='saved' color='primary'><v-icon>mdi-content-save</v-icon></v-btn>
        </template>
        <template v-else>
          <v-btn fab small text dark @click='clickEdit'><v-icon>mdi-pencil</v-icon></v-btn>
          <v-btn fab small text dark @click='toggleView'><v-icon>mdi-circle-opacity</v-icon></v-btn>
        </template>
      </div>
    </div>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$toolbarHeight: 60px;
#drawing{
  display: grid;
  place-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}
#toolbarArea{
  width: 100%;
  height: $toolbarHeight;
  text-align: center;
  position: absolute;
  top: -$toolbarHeight;
  #toolbarInner{
    display: inline-block;
    border-radius: 10px;
    background-color: rgba(white, 25%);
  }
}
#canvasArea{
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  display: grid;
  place-items: center;
  .layer{
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>

<script>
//import { /* mapGetters, */  mapActions } from 'vuex';
import Debounce from '@/helpers/Debounce2'
export default {
  name: '',
  components: {},
  props: {
    width: { type: Number },
    height: { type: Number },
    scale: { type: Number },
    save: { type: Function },
    srcMarkup: { type: String },
  },
  data(){
    return {
      mouse: { x: null, y: null, down: false },
      tool: 'draw',
      colour: 'black',
      size: 's',
      colours: ['black', 'red', 'orange', 'yellow', 'green', 'blue'],
      edit: false,
      show: true,
      saving: false,
      saved: true,
      viewMode: 0,
      mouseMoveDx: null,
    }
  },
  computed: {
    canvas_draw(){ return this.$refs['canvas_draw']; },
    ctx(){ return this.canvas_draw.getContext("2d"); },
    canvas_mouse(){ return this.$refs['canvas_mouse']; },
    ctx_mouse(){ return this.canvas_mouse.getContext("2d"); },
    lineWidth(){
      let sizes = {
        draw:   { s: 2, m: 10, l: 20 },
        eraser: { s: 50, m: 100, l: 150 }
        //make eraser a fixed size might be better?
      };
      return sizes[this.tool][this.size];/* /(this.scale || 1); */
    }
  },
  watch: {
    colour(){ this.tool = 'draw'; this.updateTool(); },
    lineWidth(){ this.updateTool(); },
    tool(){ this.updateTool(); },
  },
  methods: {
    toggleView(){
      let mode = this.viewMode + 1;
      if(mode > 2) mode = 0;
      if(mode == 2 && this.edit) mode = 0;
      this.viewMode = mode;
      this.updateTool();
    },
    clickEdit(){
      if(this.viewMode == 2) this.viewMode = 0;
      this.edit = true;
      this.show = true;
      this.$emit('save', false);
    },
    clickSave(){
      //this.edit = false;
      this.saving = true;
      this.save(this.canvas_draw.toDataURL())
      .then(() => {
        this.saved = true;
        this.edit = false;
      })
      .catch(()=>{

      })
      .finally(() => { this.saving = false; });
    },
    updateTool(){
      this.ctx.lineCap = "round";
      this.ctx.strokeStyle = this.colour;
      this.ctx.fillStyle = this.colour;
      this.ctx.lineWidth = this.lineWidth*2;
      this.ctx.globalCompositeOperation = (this.tool == "draw" ? 'source-over' : 'destination-out');
      this.ctx_mouse.lineCap = "round";
      this.ctx_mouse.strokeStyle = this.tool == 'draw' ? this.colour : 'black';
      this.ctx_mouse.fillStyle = this.tool == 'draw' ? this.colour : '#FFF5';
    },
    mouseDown(e){
      if((e.which ?? null) == 3){ this.toggleTool(); e.preventDefault(); return; } //right click change tool
      this.updateMousePosition(e);
      this.mouse = { ...this.mouse, down: true, line: null };
      if(e.shiftKey && this.tool == 'draw') this.mouse.line = { x: this.mouse.x, y: this.mouse.y };
      this.saved = false;
    },
    mouseUp(){
      this.mouse.down = false;
      if(this.mouse.line){
        this.drawLine(this.mouse.x, this.mouse.y, this.mouse.line.x, this.mouse.line.y);
        this.mouse.line = null;
      }
    },
    mouseMove(e){
      // get previous coords.
      let { x, y } = this.mouse;
      //updates
      this.updateMousePosition(e); // position
      this.drawCursor(); // cursor overlay
      //check if starting to draw a line
      if(this.mouse.down && e.shiftKey && !this.mouse.line && this.tool == 'draw') this.mouse.line = { x: this.mouse.x, y: this.mouse.y };
      // check if stopped drawing line
      if(this.mouse.line && !e.shiftKey){ this.mouseUp(); return; }
      // Stop here if not drawing
      if(!this.mouse.down || this.mouse.line) return;
      // Draw line from last point
      if(x && y) this.drawLine(this.mouse.x, this.mouse.y, x, y);
    },
    updateMousePosition(e){
      let event_x = e.x ?? e.touches[0].clientX; // mouse or touch event
      let event_y = e.y ?? e.touches[0].clientY;
      let area = this.canvas_mouse.getBoundingClientRect(); //scale coordinates
      this.mouse = { ...this.mouse,
        x: (event_x - area.x)/this.scale,
        y: (event_y - area.y)/this.scale
      };
    },
    drawLine(x1, y1, x2, y2){
      this.ctx.beginPath();
      this.ctx.moveTo(x1, y1);
      this.ctx.lineTo(x2, y2);
      this.ctx.stroke();
    },
    drawCursor(){
      this.ctx_mouse.clearRect(0,0,this.canvas_mouse.width, this.canvas_mouse.height);
      this.ctx_mouse.lineWidth = 2;
      let {x, y} = this.mouse;
      if(x === null || y === null) return;
      this.ctx_mouse.beginPath();
      this.ctx_mouse.arc(x, y, this.lineWidth, 0, 2*Math.PI);
      this.ctx_mouse.fill();
      this.ctx_mouse.stroke();
      if(this.mouse.line && this.mouse.down && this.tool == 'draw'){
        this.ctx_mouse.lineWidth = this.lineWidth * 2;
        this.ctx_mouse.beginPath();
        this.ctx_mouse.moveTo(this.mouse.line.x, this.mouse.line.y);
        this.ctx_mouse.lineTo(x, y);
        this.ctx_mouse.stroke();
      }
    },
    loadDrawing(){
      if(!this.srcMarkup) return;
      var img = new Image;
      img.src = this.srcMarkup;
      img.onload = () => {
        this.ctx.width = img.width;
        this.ctx.height = img.height;
        this.ctx.drawImage(img, 0, 0);
      };
    },
    toggleTool(){
      this.tool=(this.tool=="draw"?"eraser":"draw");
      this.updateTool();
      this.drawCursor();
    },
  },
  created(){},
  mounted(){
    this.updateTool();
    (new ResizeObserver(this.updateTool)).observe(this.$refs.canvas_draw);
    this.loadDrawing();
    this.mouseMoveDx = new Debounce(this.mouseMove);
  }
}
</script>
