var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "ImageViewer"
    }
  }, [_vm.active ? _c('v-dialog', {
    attrs: {
      "value": _vm.active,
      "fullscreen": "",
      "transition": "dialog-bottom-transition"
    }
  }, [_c('v-container', {
    attrs: {
      "id": "container",
      "fill-height": "",
      "fluid": ""
    }
  }, [_c('MarkupImage', {
    attrs: {
      "id": "MarkupImage",
      "src": _vm.src,
      "srcMarkup": _vm.srcMarkup,
      "save": _vm.onSave
    }
  })], 1), _c('div', {
    staticClass: "pa-4",
    staticStyle: {
      "position": "fixed",
      "top": "0px",
      "right": "0px",
      "width": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "dark": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-close")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }