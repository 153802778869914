<template>
  <div id='MarkupImage'>
      <div class='white' style='margin:auto;position: relative;' :style="{ width: `${inner.width*inner.scale-1}px`, height: `${inner.height*inner.scale-1}px`, top: `calc(50% - ${(inner.height*inner.scale-1)/2}px)` }"></div>
      <!--<v-img id='bg' :src='bg.src' contain ref='bg'></v-img>-->
        <div ref='bg'
          style='width:100%;height:100%;position:absolute;top:0;background-position:center;background-size:contain;background-repeat:no-repeat;'
          :style='{ backgroundImage: `url("${src}")` }'
        >
      </div>
      <Markup id='drawing'
        v-if='(srcMarkup || save) && ready'
        :srcMarkup='srcMarkup'
        :width='inner.width' :height='inner.height' :scale='inner.scale'
        :save="save"
      />
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$toolbarHeight: 60px;
#MarkupImage{
  width: 100%;
  height: calc(100% - #{$toolbarHeight});
  position: relative;
  top: $toolbarHeight/2;
  #bg{
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  #drawing{
    position: absolute;
    left: 0px;
    top: 0px;
  }
}
</style>

<script>
//import { /* mapGetters, */  mapActions } from 'vuex';
import Markup from './Markup';
export default {
  name: 'markup',
  components: { Markup },
  props: {
    src: { type: String },
    srcMarkup: { type: String },
    save: { type: Function },
  },
  data(){
    return {
      bg: { img: null, width: null, height: null },
      inner: { width: null, height: null, scale: null },
      ready: false,
    }
  },
  watch:{
    src(){ this.loadImage(); },
  },
  computed: {
  },
  methods: {
    loadImage(){
      this.ready = false;
      this.bg.img = new Image;
      this.bg.img.onload = () => { this.bgLoaded(); };
      this.bg.img.src = this.src;
    },
    bgLoaded(){
      this.bg.width = this.bg.img.width;
      this.bg.height = this.bg.img.height;
      this.adaptSize();
      this.ready = true;
    },
    observeResizing(){
      let observer = new ResizeObserver(this.adaptSize);
      observer.observe(this.$refs.bg);
    },
    adaptSize(){
      // size of space loaded photo must fit within
      let bgSpace = this.$refs.bg.getBoundingClientRect();
      //calculate the displayed image size on screen
      let scale_display = Math.min(bgSpace.width / this.bg.width, bgSpace.height / this.bg.height);
      //calculate the canvas resolution (longer size of LONG px, as a ratio of bg resolution)
      let LONG_DIMENSION = 3500; // this is the height of A4 @ 300 DPI
      let scale_1000 = LONG_DIMENSION / Math.max(this.bg.width, this.bg.height);
      this.inner.width = Math.round(this.bg.width * scale_1000);
      this.inner.height = Math.round(this.bg.height * scale_1000);
      //provide a scale for inner to match background
      this.inner.scale = scale_display / scale_1000;
    }
  },
  created(){
  },
  mounted(){
    this.observeResizing();
    this.loadImage();
  }
}
</script>
